var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "商品详情",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "添加储位"
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.showAddActionSheet = true;
      }
    }
  }), _vm.goods ? _c('div', {
    staticClass: "app-main-container"
  }, [_vm.goods.in_warehouses.length > 0 ? _c('van-cell-group', {
    attrs: {
      "title": "存货仓"
    }
  }, _vm._l(_vm.goods.in_warehouses, function (inWarehouse, index) {
    return _c('van-swipe-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticStyle: {
              "height": "100%"
            },
            attrs: {
              "type": "danger",
              "square": ""
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(inWarehouse.id);
              }
            }
          }, [_vm._v("删除")])];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "value": '储位：' + inWarehouse.storage,
        "center": "",
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleOperate(inWarehouse);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "warehouse-title"
          }, [_c('span', [_vm._v("仓库：" + _vm._s(inWarehouse.warehouse ? inWarehouse.warehouse.name : ''))]), inWarehouse.is_default ? _c('van-tag', {
            attrs: {
              "type": "success"
            }
          }, [_vm._v("默认")]) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1) : _vm._e(), _vm.goods.return_warehouses.length > 0 ? _c('van-cell-group', {
    attrs: {
      "title": "退货仓"
    }
  }, _vm._l(_vm.goods.return_warehouses, function (returnWarehouse, index) {
    return _c('van-swipe-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticStyle: {
              "height": "100%"
            },
            attrs: {
              "type": "danger",
              "square": ""
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(returnWarehouse.id);
              }
            }
          }, [_vm._v("删除")])];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "value": '储位：' + returnWarehouse.storage,
        "center": "",
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleOperate(returnWarehouse);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "warehouse-title"
          }, [_c('span', [_vm._v("仓库：" + _vm._s(returnWarehouse.warehouse ? returnWarehouse.warehouse.name : ''))]), returnWarehouse.is_default ? _c('van-tag', {
            attrs: {
              "type": "success"
            }
          }, [_vm._v("默认")]) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  }), _c('van-dialog', {
    staticClass: "modify-dialog",
    attrs: {
      "title": "修改储位",
      "show-cancel-button": "",
      "confirm-button-color": "#1989fa",
      "confirm-button-text": "确定"
    },
    on: {
      "confirm": _vm.handleModify
    },
    model: {
      value: _vm.showModifyDialog,
      callback: function callback($$v) {
        _vm.showModifyDialog = $$v;
      },
      expression: "showModifyDialog"
    }
  }, [_c('van-cell', {
    staticClass: "modify-dialog__item",
    attrs: {
      "title": "当前仓库：",
      "value": _vm.warehouseName,
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showWarehouseActionSheet = true;
      }
    }
  }), _c('van-field', {
    staticClass: "modify-dialog__item",
    attrs: {
      "label": "当前储位：",
      "placeholder": "请输入储位"
    },
    model: {
      value: _vm.modifyData.storage,
      callback: function callback($$v) {
        _vm.$set(_vm.modifyData, "storage", $$v);
      },
      expression: "modifyData.storage"
    }
  }), _c('van-field', {
    attrs: {
      "label": "默认"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal"
          },
          model: {
            value: _vm.modifyData.is_default,
            callback: function callback($$v) {
              _vm.$set(_vm.modifyData, "is_default", $$v);
            },
            expression: "modifyData.is_default"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 1
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": 0
          }
        }, [_vm._v("否")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.currentWarehouses
    },
    on: {
      "select": _vm.handleWarehouseSelect
    },
    model: {
      value: _vm.showWarehouseActionSheet,
      callback: function callback($$v) {
        _vm.showWarehouseActionSheet = $$v;
      },
      expression: "showWarehouseActionSheet"
    }
  }), _c('div', {
    staticClass: "stocktaking-footer"
  }, [_c('van-button', {
    staticClass: "stocktaking-footer-btn",
    attrs: {
      "square": "",
      "type": "primary"
    },
    on: {
      "click": _vm.handleStocktaking
    }
  }, [_vm._v("盘点库存")]), _c('van-button', {
    staticClass: "stocktaking-footer-btn",
    attrs: {
      "square": "",
      "type": "warning"
    },
    on: {
      "click": _vm.handleStocktakingLog
    }
  }, [_vm._v("盘点记录")]), _c('van-button', {
    staticClass: "stocktaking-footer-btn",
    attrs: {
      "square": "",
      "type": "danger"
    },
    on: {
      "click": _vm.handleStockLogs
    }
  }, [_vm._v("库存记录")])], 1), _c('van-dialog', {
    staticClass: "stocktaking-dialog",
    attrs: {
      "title": "盘点库存",
      "show-cancel-button": "",
      "confirm-button-color": "#1989fa",
      "confirm-button-text": "确定"
    },
    on: {
      "confirm": _vm.handleStocktakingConfirm,
      "cancel": _vm.handleCancelStocktaking
    },
    model: {
      value: _vm.showStocktakingDialog,
      callback: function callback($$v) {
        _vm.showStocktakingDialog = $$v;
      },
      expression: "showStocktakingDialog"
    }
  }, [_c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：盘点库存请输入实际盘点的库存数量（包括未配送库存）")]), _c('van-cell', {
    staticClass: "stocktaking-dialog__item",
    attrs: {
      "title-class": "stocktaking-dialog__title",
      "value-class": "stocktaking-dialog__value",
      "title": "账面库存：",
      "value": _vm.stocktakingData.book_stock + _vm.stocktakingData.least_unit
    }
  }), _c('van-cell', {
    staticClass: "stocktaking-dialog__item",
    attrs: {
      "title-class": "stocktaking-dialog__title",
      "value-class": "stocktaking-dialog__value",
      "title": "未配送库存：",
      "value": _vm.stocktakingData.ndv_stock + _vm.stocktakingData.least_unit,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showOrderNums = true;
      }
    }
  }), _c('van-cell', {
    staticClass: "stocktaking-dialog__item",
    attrs: {
      "title-class": "stocktaking-dialog__title",
      "value-class": "stocktaking-dialog__value",
      "title": "实际库存：",
      "value": _vm.stocktakingData.stock + _vm.stocktakingData.least_unit
    }
  }), _c('van-field', {
    staticClass: "stocktaking-dialog__item",
    attrs: {
      "label": "盘点库存：",
      "placeholder": "请输入库存"
    },
    model: {
      value: _vm.stocktakingData.real_stock,
      callback: function callback($$v) {
        _vm.$set(_vm.stocktakingData, "real_stock", $$v);
      },
      expression: "stocktakingData.real_stock"
    }
  }), _c('van-field', {
    staticClass: "stocktaking-dialog__item",
    attrs: {
      "type": "textarea",
      "maxlength": "255",
      "show-word-limit": "",
      "label": "盘点日志：",
      "placeholder": "请输入日志"
    },
    model: {
      value: _vm.stocktakingData.log,
      callback: function callback($$v) {
        _vm.$set(_vm.stocktakingData, "log", $$v);
      },
      expression: "stocktakingData.log"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.warehouses
    },
    on: {
      "select": _vm.handleAddType
    },
    model: {
      value: _vm.showAddActionSheet,
      callback: function callback($$v) {
        _vm.showAddActionSheet = $$v;
      },
      expression: "showAddActionSheet"
    }
  }), _c('my-dialog', {
    attrs: {
      "title": "添加储位"
    },
    on: {
      "confirm": _vm.addStorage
    },
    model: {
      value: _vm.showAddDialog,
      callback: function callback($$v) {
        _vm.showAddDialog = $$v;
      },
      expression: "showAddDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "储位类型"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal"
          },
          model: {
            value: _vm.addData.type,
            callback: function callback($$v) {
              _vm.$set(_vm.addData, "type", $$v);
            },
            expression: "addData.type"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 1
          }
        }, [_vm._v("存货")]), _c('van-radio', {
          attrs: {
            "name": 2
          }
        }, [_vm._v("退货")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-field', {
    attrs: {
      "label": "储位",
      "placeholder": "请输入储位"
    },
    model: {
      value: _vm.addData.storage,
      callback: function callback($$v) {
        _vm.$set(_vm.addData, "storage", $$v);
      },
      expression: "addData.storage"
    }
  }), _c('van-field', {
    attrs: {
      "label": "默认"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal"
          },
          model: {
            value: _vm.addData.is_default,
            callback: function callback($$v) {
              _vm.$set(_vm.addData, "is_default", $$v);
            },
            expression: "addData.is_default"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 1
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": 0
          }
        }, [_vm._v("否")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('my-dialog', {
    attrs: {
      "title": "未配送库存订单",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showOrderNums,
      callback: function callback($$v) {
        _vm.showOrderNums = $$v;
      },
      expression: "showOrderNums"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": ""
    }
  }, [_c('van-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": _vm.copyAllOrderNums
    }
  }, [_vm._v("全部复制")])], 1), _vm._l(_vm.orderNums, function (orderNum, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "title": orderNum
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }