<template>
  <div>
    <my-nav-bar
      title="商品详情"
      left-text="返回"
      left-arrow
      right-text="添加储位"
      @click-right="showAddActionSheet = true"
    />
    <div v-if="goods" class="app-main-container">
      <van-cell-group v-if="goods.in_warehouses.length > 0" title="存货仓">
        <van-swipe-cell v-for="(inWarehouse, index) in goods.in_warehouses" :key="index">
          <van-cell
            :value="'储位：' + inWarehouse.storage"
            center
            is-link
            @click="handleOperate(inWarehouse)"
          >
            <template #title>
              <div class="warehouse-title">
                <span>仓库：{{ inWarehouse.warehouse ? inWarehouse.warehouse.name : '' }}</span>
                <van-tag v-if="inWarehouse.is_default" type="success">默认</van-tag>
              </div>
            </template>
          </van-cell>
          <template #right>
            <van-button type="danger" square style="height: 100%" @click="handleDelete(inWarehouse.id)">删除</van-button>
          </template>
        </van-swipe-cell>
      </van-cell-group>

      <van-cell-group v-if="goods.return_warehouses.length > 0" title="退货仓">
        <van-swipe-cell v-for="(returnWarehouse, index) in goods.return_warehouses" :key="index">
          <van-cell
            :value="'储位：' + returnWarehouse.storage"
            center
            is-link
            @click="handleOperate(returnWarehouse)"
          >
            <template #title>
              <div class="warehouse-title">
                <span>仓库：{{ returnWarehouse.warehouse ? returnWarehouse.warehouse.name : '' }}</span>
                <van-tag v-if="returnWarehouse.is_default" type="success">默认</van-tag>
              </div>
            </template>
          </van-cell>
          <template #right>
            <van-button type="danger" square style="height: 100%" @click="handleDelete(returnWarehouse.id)">删除</van-button>
          </template>
        </van-swipe-cell>
      </van-cell-group>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="handleSelect" />
    <van-dialog
      v-model="showModifyDialog"
      class="modify-dialog"
      title="修改储位"
      show-cancel-button
      confirm-button-color="#1989fa"
      confirm-button-text="确定"
      @confirm="handleModify"
    >
      <van-cell class="modify-dialog__item" title="当前仓库：" :value="warehouseName" is-link @click="showWarehouseActionSheet = true" />
      <van-field v-model="modifyData.storage" class="modify-dialog__item" label="当前储位：" placeholder="请输入储位" />
      <van-field label="默认">
        <template #input>
          <van-radio-group
            v-model="modifyData.is_default"
            direction="horizontal"
          >
            <van-radio :name="1">是</van-radio>
            <van-radio :name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-dialog>
    <van-action-sheet v-model="showWarehouseActionSheet" :actions="currentWarehouses" @select="handleWarehouseSelect" />
    <div class="stocktaking-footer">
      <van-button class="stocktaking-footer-btn" square type="primary" @click="handleStocktaking">盘点库存</van-button>
      <van-button class="stocktaking-footer-btn" square type="warning" @click="handleStocktakingLog">盘点记录</van-button>
      <van-button class="stocktaking-footer-btn" square type="danger" @click="handleStockLogs">库存记录</van-button>
    </div>
    <van-dialog
      v-model="showStocktakingDialog"
      class="stocktaking-dialog"
      title="盘点库存"
      show-cancel-button
      confirm-button-color="#1989fa"
      confirm-button-text="确定"
      @confirm="handleStocktakingConfirm"
      @cancel="handleCancelStocktaking"
    >
      <div class="tips">提示：盘点库存请输入实际盘点的库存数量（包括未配送库存）</div>
      <van-cell
        class="stocktaking-dialog__item"
        title-class="stocktaking-dialog__title"
        value-class="stocktaking-dialog__value"
        title="账面库存："
        :value="stocktakingData.book_stock + stocktakingData.least_unit"
      />
      <van-cell
        class="stocktaking-dialog__item"
        title-class="stocktaking-dialog__title"
        value-class="stocktaking-dialog__value"
        title="未配送库存："
        :value="stocktakingData.ndv_stock + stocktakingData.least_unit"
        is-link
        center
        @click="showOrderNums = true"
      />
      <van-cell
        class="stocktaking-dialog__item"
        title-class="stocktaking-dialog__title"
        value-class="stocktaking-dialog__value"
        title="实际库存："
        :value="stocktakingData.stock + stocktakingData.least_unit"
      />
      <van-field v-model="stocktakingData.real_stock" class="stocktaking-dialog__item" label="盘点库存：" placeholder="请输入库存" />
      <van-field
        v-model="stocktakingData.log"
        class="stocktaking-dialog__item"
        type="textarea"
        maxlength="255"
        show-word-limit
        label="盘点日志："
        placeholder="请输入日志"
      />
    </van-dialog>
    <van-action-sheet
      v-model="showAddActionSheet"
      :actions="warehouses"
      @select="handleAddType"
    />
    <my-dialog
      v-model="showAddDialog"
      title="添加储位"
      @confirm="addStorage"
    >
      <van-field label="储位类型">
        <template #input>
          <van-radio-group
            v-model="addData.type"
            direction="horizontal"
          >
            <van-radio :name="1">存货</van-radio>
            <van-radio :name="2">退货</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="addData.storage"
        label="储位"
        placeholder="请输入储位"
      />

      <van-field label="默认">
        <template #input>
          <van-radio-group
            v-model="addData.is_default"
            direction="horizontal"
          >
            <van-radio :name="1">是</van-radio>
            <van-radio :name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

    </my-dialog>

    <my-dialog
      v-model="showOrderNums"
      title="未配送库存订单"
      :show-cancel-button="false"
    >
      <van-cell title="">
        <van-button type="primary" size="mini" @click="copyAllOrderNums">全部复制</van-button>
      </van-cell>
      <van-cell v-for="(orderNum, index) in orderNums" :key="index" :title="orderNum" />
    </my-dialog>

  </div>
</template>

<script>
import warehouseGoodsApi from '@/api/warehouse_goods'
import warehouseApi from '@/api/warehouse'
import MyDialog from '@/components/my-dialog'
import { addStorage, delGoodsWarehouse } from '@/api/warehouse_goods'
import MyNavBar from '@/components/my-nav-bar'
export default {
  name: 'WarehouseGoodsDetail',
  components: { MyDialog, MyNavBar },
  data() {
    return {
      goods: null,
      warehouses: null,
      currentWarehouses: null,
      operateWarehouse: null,
      showActionSheet: false,
      actions: [
        { name: '修改储位', type: 1 }
        // { name: '盘点库存', type: 2 },
        // { name: '盘点记录', type: 3 }
      ],
      showModifyDialog: false,
      showStocktakingDialog: false,
      showWarehouseActionSheet: false,
      modifyData: {
        goods_warehouse_id: undefined,
        warehouse_id: undefined,
        storage: undefined
      },
      warehouseName: '',
      stocktakingData: {
        goods_warehouse_id: undefined,
        stock: undefined,
        log: undefined,
        least_unit: undefined
      },
      notDecrementVolume: 0,
      showAddActionSheet: false,
      addAtions: [
        { name: '存货仓', type: 1 },
        { name: '退货仓', type: 2 }
      ],
      showAddDialog: false,
      addData: {
        goods_id: this.$route.query.goods_id,
        warehouse_id: '',
        type: '',
        storage: '',
        is_default: 1
      },
      showOrderNums: false,
      orderNums: []
    }
  },
  created() {
    this.getDetail()
    this.getWarehouses()
  },
  methods: {
    getDetail() {
      const params = {
        goods_id: this.$route.query.goods_id
      }
      warehouseGoodsApi.detail(params).then(res => {
        this.goods = res.data
      })
    },
    getWarehouses() {
      warehouseApi.list().then(res => {
        this.warehouses = res.data
      })
    },
    handleOperate(operateWarehouse) {
      this.operateWarehouse = operateWarehouse
      this.showActionSheet = true
    },
    handleSelect(action) {
      if (action.type === 1) {
        // 修改储位
        this.showModifyDialog = true
        this.modifyData = {
          goods_warehouse_id: this.operateWarehouse.id,
          warehouse_id: this.operateWarehouse.warehouse_id,
          storage: this.operateWarehouse.storage,
          is_default: this.operateWarehouse.is_default
        }
        this.warehouseName = this.operateWarehouse.warehouse ? this.operateWarehouse.warehouse.name : ''
        this.currentWarehouses = this.warehouses.filter(warehouse => {
          return warehouse.type === this.operateWarehouse.type
        })
      } else if (action.type === 2) {
        // this.confirm('确定盘点此商品的库存吗？注：开始盘点后，商品将无法下单', '提示').then(() => {
        // const data = {
        //   goods_id: this.$route.query.goods_id
        // }
        // this.beginLoad()
        // warehouseGoodsApi.lock(data).then(res => {
        //   this.endLoad()
        //   this.notDecrementVolume = res.data.not_decrement_volume
        //   // 盘点库存
        //   this.showStocktakingDialog = true
        //   this.stocktakingData = {
        //     goods_warehouse_id: this.operateWarehouse.id,
        //     stock: this.operateWarehouse.stock,
        //     log: ''
        //   }
        // })
        // })
      } else {
        // 盘点记录
        // this.$router.push({
        //   path: '/stock-taking-record',
        //   query: {
        //     goods_id: this.$route.query.goods_id,
        //     goods_warehouse_id: this.operateWarehouse.id
        //   }
        // })
      }
      this.showActionSheet = false
    },
    handleStocktaking() {
      const data = {
        goods_id: this.$route.query.goods_id
      }
      this.beginLoad()
      warehouseGoodsApi.lock(data).then(res => {
        this.endLoad()
        // 盘点库存
        this.showStocktakingDialog = true
        this.stocktakingData = {
          goods_id: this.$route.query.goods_id,
          book_stock: res.data.book_stock,
          ndv_stock: res.data.ndv_stock,
          stock: res.data.stock,
          log: '',
          real_stock: res.data.stock,
          least_unit: res.data.least_unit
        }
        this.orderNums = res.data.order_nums
      })
    },
    handleStocktakingLog() {
      this.$router.push({
        path: '/stock-taking-record',
        query: {
          goods_id: this.$route.query.goods_id
          // goods_warehouse_id: this.operateWarehouse.id
        }
      })
    },
    handleWarehouseSelect(warehouse) {
      this.modifyData.warehouse_id = warehouse.id
      this.warehouseName = warehouse.name
      this.showWarehouseActionSheet = false
    },
    handleModify() {
      this.beginLoad()
      if (!this.modifyData.warehouse_id) {
        this.fail('请选择仓库')
        return
      }
      if (this.modifyData.storage.length === 0) {
        this.fail('请输入储位')
        return
      }
      warehouseGoodsApi.modify(this.modifyData).then(res => {
        this.success(res.msg)
        this.getDetail()
      })
    },
    handleStocktakingConfirm() {
      // const log = this.stocktakingData.log.trim()
      // if (log.length === 0) {
      //   this.fail('盘点日志不能为空')
      //   return
      // }
      this.beginLoad()
      const data = Object.assign({}, this.stocktakingData)
      data.is_review = this.$route.query.is_stocktkaing_review
      data.stocktaking_plan_id = this.$route.query.stocktaking_plan_id
      warehouseGoodsApi.stocktaking(data).then(res => {
        this.success(res.msg)
        this.$eventBus.$emit('update-stock', {
          goods_id: this.$route.query.goods_id,
          book_stock: Number(this.stocktakingData.real_stock) - Number(this.stocktakingData.ndv_stock)
        })
        this.$eventBus.$emit('stocktaking-finished', {
          goods_id: this.$route.query.goods_id
        })
      })
    },
    handleCancelStocktaking() {
      this.confirm('需要解除盘点锁定吗？').then(() => {
        this.beginLoad()
        const data = {
          goods_id: this.$route.query.goods_id
        }
        warehouseGoodsApi.unlock(data).then(() => {
          this.endLoad()
        })
      })
    },
    handleAddType(action) {
      this.showAddActionSheet = false
      this.showAddDialog = true
      this.addData = {
        goods_id: this.$route.query.goods_id,
        warehouse_id: action.id,
        type: 1,
        storage: '',
        is_default: 1
      }
    },
    addStorage() {
      addStorage(this.addData).then(res => {
        this.success(res.msg)
        this.getDetail()
      })
    },
    handleDelete(goodsWarehouseId) {
      this.confirm('确定要删除吗？', '提示').then(() => {
        delGoodsWarehouse({ id: goodsWarehouseId }).then(res => {
          this.success(res.msg)
          this.getDetail()
        })
      })
    },
    handleStockLogs() {
      this.$router.push({
        path: '/stock-logs',
        query: {
          goods_id: this.$route.query.goods_id
        }
      })
    },
    copyAllOrderNums() {
      const orderNumStr = this.orderNums.join(' ')
      this.$copyText(orderNumStr).then(() => {
        this.success('复制成功')
      }).catch(() => {
        this.fail('复制失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modify-dialog {
    &__item {
      padding: 15px;
    }
  }
  .stocktaking-dialog {
    &__item {
      padding: 15px;
    }
    &__title {
      flex: none;
      box-sizing: border-box;
      width: 6.2em;
      margin-right: 0.32rem;
      color: #646566;
      text-align: left;
      word-wrap: break-word;
    }
    &__value {
      text-align: left;
    }
  }
  .warehouse-title {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
  .stocktaking-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: env(safe-area-inset-bottom);
    bottom: constant(safe-area-inset-bottom);
    display: flex;
  }
  .stocktaking-footer-btn {
    flex: 1;
  }
  .tips {
    font-size: 13px;
    color: #989898;
    padding: 15px;
  }
</style>
